import React from 'react'

export const PerfilEstudiante = () => {
    return (
        <>
            <h2 className="has-text-white">Perfil del estudiante</h2>
            <p>Los estudiantes del Colegio Cielos del Valle se responsabilizarán progresivamente de su formación y crecimiento personal, mostrando una actitud permanente de superación tanto personal como escolar y mostrarán motivación hacia el logro de los aprendizajes.</p>
            <p>El colegio formará estudiantes que serán en un futuro:</p>
            <ul>
                <li>Capaces de tener una actitud positiva frente a la vida, mostrando un espíritu de emprendimiento en las tareas encomendadas.</li>
                <li>Responsables de su formación personal, social y académica.</li>
                <li>Conscientes de que los conocimientos adquiridos en el colegio serán las bases para su formación futura.</li>
                <li>Conscientes de que la disciplina es un buen aliado para conseguir éxito en la vida.</li>
                <li>Capaces de establecer relaciones sociales que se sustentan en el respeto y el compañerismo.</li>
                <li>Respetuosos de su propia integridad física y de los demás.</li>
                <li>Comprometidos con sus ideales, mostrando convicción de que los sueños se pueden lograr.</li>
            </ul>
        </>
    )
}
