import React from 'react'
import { Row, Col } from 'react-bootstrap';
import * as Gatsby from 'gatsby'

export const Historia = () => {
    const { colegio } = Gatsby.useStaticQuery(Gatsby.graphql`
        query {
            colegio: file(relativePath: {eq: "colegio/portadacolegio.JPG"}){
                publicURL
            }
        }
    `)
    return (
        <Row>
            <Col xs={12} md={7} lg={8}>
                <h4>Nuestra Historia</h4>
                <p>
                    El colegio “Cielos del Valle”, nace de la necesidad de ofrecer continuidad en la educación formal a los estudiantes pertenecientes a la Escuela Especial de Lenguaje” Happy Day”, situación que refleja el desarrollo de este proyecto inicial, y de la insistente solicitud de los padres y apoderados de los estudiantes, que ven esta alternativa como una respuesta a su búsqueda de calidad en el proceso educativo que requieren para sus hijos.
                </p>
                
            </Col>
            <Col xs={12} md={5} lg={4}>
                <img 
                    src={colegio.publicURL}
                    alt="" 
                    className="img-fluid img-thumbnail"
                /> 
            </Col>
            <Col md={12}>
                <p >
                    La Escuela Especial de Lenguaje surgió a partir de la creación del Jardín infantil Particular que lleva el mismo nombre, fundado en marzo de 2002. Las acciones descritas se fundamentan en la necesidad de la comunidad de la Compañía Alta de tener en el sector un jardín infantil particular, donde padres y apoderados dejen a sus hijos, entre las edades de dos años a cinco años y once meses (niveles: medio menor, medio mayor, primer y segundo nivel de transición), para que inicien su educación formal en un Establecimiento Educacional que no pertenece ni a la Junta Nacional de Jardines Infantiles (JUNJI), ni a la Fundación INTEGRA. 
                </p>
                <p >
                    El horario respondía a la necesidad de cada familia; las alternativas eran de media jornada en la mañana o la tarde, o jornada completa de 8:30 a 17:30 hrs. El Establecimiento garantizaba una educación personalizada, dada la cantidad de alumnos por nivel, que alcanzaba a diez alumnos aproximadamente.
                </p>
                <p>
                    En el año 2011 se decide cambiar de Jardín Infantil Particular a Escuela Especial de Lenguaje, por la gran demanda de atención a las dificultades de lenguaje que presentan los estudiantes del sector, lo que también se vio reflejado en las reiteradas sugerencias de apoderados del  mismo Jardín infantil. 
                </p>
                <p >
                    Se comenzó con dos salas, atendiendo cuatro cursos: dos de nivel medio mayor de once alumnos cada uno, y dos de primer nivel de transición de quince alumnos cada uno. En este mismo año, en el mes de Mayo se recibió el Reconocimiento OFICIAL n° 40405-5 otorgado por el Ministerio de Educación.  El año 2012 se construye una tercera sala para trece alumnos, por lo cual se amplió la cobertura al segundo nivel de transición.
                </p>
                <p >
                    Es así como se encuentra funcionando el Establecimiento en la actualidad con un 100% de matrícula proyectada, que corresponde a setenta y seis alumnos y alumnas.
                </p>
            </Col>
        </Row>
    )
}



