import React from 'react'
import { useDispatch } from 'react-redux';

import '../../styles/galeria/galeria.min.scss';
import '../../styles/galeria/swiper.scss';
import { guardarGaleriaMultimedia } from '../../action/galeria';
import * as gatsby from 'gatsby'
import { openGaleria } from '../../action/admin';

 export const MenuImg = ({galeria}) => {
	 const dispatch=useDispatch();
	const handleClick=(galeria)=>{
		gatsby.navigate('/colegio')
		dispatch(guardarGaleriaMultimedia(galeria.id))
		dispatch(openGaleria())
	}
	
    return (
        <div className="col-lg-4 mb-2">
		    <div className="flip-card text-center">
		    	<div className="flip-card-front" data-height-xl="505" style={{backgroundImage: `url(http://167.71.109.215:1337${galeria.imagenes[0].url})`}}>
		    		<div className="flip-card-inner">
		    			<div className="card nobg noborder text-center">
		    				<div className="card-body">
		    					<i className="icon-user-graduate h1"></i>
		    					<h5 className="card-title">{galeria.titulo.toUpperCase()}</h5>
        
		    				</div>
		    			</div>
		    		</div>
		    	</div>
		    	<div className="flip-card-back no-after" data-height-xl="505">
		    		<div className="flip-card-inner" >										
		    			<button onClick={()=>handleClick(galeria)} className="btn btn-outline-light mt-2"><span>Ver Fotografias</span> <i className="icon-angle-right"></i></button>
		    		</div>
		    	</div>
		    </div>
	    </div>
    )
}

// const createPages = async (galeria, {actions}) => {
// 	actions.createPage({
// 		path: urlSlug(galeria.id),
// 		component: require.resolve('./cardGaleriaImagen.js'),
// 		context: {
// 			id: galeria.id
// 		}
// 	})
	
// }
