import { graphql, useStaticQuery } from 'gatsby'

const useGaleriaImagenes = () => {
    const resultado = useStaticQuery(graphql`
    {
      allStrapiGaleriaMultimedias {
      nodes {
        fecha
        id
        descripcion
        titulo
        imagenes {
          url
        }
      }
    }
  }
  `)
  return resultado.allStrapiGaleriaMultimedias.nodes
   
}

export default useGaleriaImagenes