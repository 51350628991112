
const usePrepareData = (imagenes) => {
    let photos=[];
    imagenes.map((imagen, index)=>(
        photos=[...photos, {
            src:`http://167.71.109.215:1337${imagen.url}`,
            width:getRandomInt(2, 4),
            height:getRandomInt(2, 4)
        }]  
    ))
  return [photos];
   
}
function getRandomInt(min, max) {
    return Math.floor(Math.random() * (max - min)) + min;
  }

export default usePrepareData;