import React from 'react'
import proyectoEducativo from '../../docs/PEICielosDelValle.pdf'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload } from '@fortawesome/free-solid-svg-icons'

export const ProyectoEducativo = () => {
    return (
        <>
            <h2>Proyecto Educativo</h2>
            <p>El Proyecto Educativo Institucional se orienta a ofrecer una educación de alta calidad pedagógica, así como la formación integral de los estudiantes, y tiene como fundamento el fortalecimiento de sus actitudes, valores, conocimientos y habilidades, de modo que puedan continuar sus estudios de nivel secundario e integrarse a la sociedad como miembros activos que contribuyen a ella.</p>
            <p>El que hacer del Colegio Cielos del Valle se enmarca en el modelo de calidad de la gestión escolar. El modelo antes mencionado no diferencia tipos de establecimientos, sino que se centra en procesos que debieran estar presentes en cualquier institución y que inciden en la calidad de los resultados educativos. Dicho modelo requiere que el establecimiento sistematice acciones, enfatiza en que los procesos de mejora deben tener metas, constantemente deben revisarse y ajustarse las estrategias, las cuales deben ser evaluadas respecto de los resultados obtenidos. Mejorar la calidad implica el mejoramiento continuo de una institución. El mejoramiento continuo se operacionaliza a través del diseño, planificación, implementación, corrección, ajuste del proceso y la evaluación permanente de las prácticas institucionales.</p>
            <p>
                <a href={proyectoEducativo} target="_blank" rel="noreferrer" className="btn btn-dark"><span><FontAwesomeIcon icon={faDownload} /> Ver proyecto completo</span></a>
            </p>
        </>
    )
}
