import React from 'react'
import { Nav, Navbar } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { openConvivenciaEscolar, openGaleriaMultimedia, openHistoria, openMensajeSostenedora, openMision, openModalidadEstudios, openPerfilEstudiante, openProyectoEducativo, openReglamentos, openVision } from '../../action/admin'

export const Menu = () => {
    const dispatch = useDispatch()
    return (
        <Navbar bg="info" variant="dark" expand="lg" fixed="bottom">
            <Navbar.Toggle  aria-controls="nav-colegio"/>
            <Navbar.Collapse id="nav-colegio">
                <Nav>
                    <Nav.Link onClick={ () => dispatch(openHistoria()) }>
                        Historia
                    </Nav.Link>
                    <Nav.Link onClick={ () => dispatch(openMision()) }>
                        Misión
                    </Nav.Link>
                    <Nav.Link onClick={ () => dispatch(openVision()) }>
                        Visión
                    </Nav.Link>
                    <Nav.Link onClick={ () => dispatch(openMensajeSostenedora()) }>
                        Mensaje sostenedora
                    </Nav.Link>
                    <Nav.Link onClick={ () => dispatch(openProyectoEducativo()) }>
                        Proyecto educativo
                    </Nav.Link>
                    <Nav.Link onClick={ () => dispatch(openConvivenciaEscolar()) }>
                        Convivencia escolar
                    </Nav.Link>
                    <Nav.Link onClick={ () => dispatch(openReglamentos()) }>
                        Reglamentos
                    </Nav.Link>
                    <Nav.Link onClick={ () => dispatch(openPerfilEstudiante()) }>
                        Perfil de estudiante
                    </Nav.Link>
                    <Nav.Link onClick={ () => dispatch(openModalidadEstudios()) }>
                        Modalidad de estudio
                    </Nav.Link>
                    <Nav.Link onClick={ () => dispatch(openGaleriaMultimedia()) }>
                        Galeria multimedia
                    </Nav.Link>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    )
}