import React, { useState, useCallback }  from "react";
import Carousel, { Modal, ModalGateway } from "react-images";
import Gallery from "react-photo-gallery";
import { useSelector } from "react-redux";
import usePrepareData from "../../hooks/usePrepareData";


export const CardGaleriaImagen = () => {
    const {galeriaActiva}=useSelector(state=>state.galeria);
    const {imagenes}=galeriaActiva;
    const[photos]=usePrepareData(imagenes);
    const [currentImage, setCurrentImage] = useState(0);
    const [viewerIsOpen, setViewerIsOpen] = useState(false);
  
    const openLightbox = useCallback((event, { photo, index }) => {
      setCurrentImage(index);
      setViewerIsOpen(true);
    }, []);
  
    const closeLightbox = () => {
      setCurrentImage(0);
      setViewerIsOpen(false);
    };
    return (
        <div style={{marginBottom:100}}>
          <h1>Galeria</h1>
          <Gallery photos={photos}   onClick={openLightbox} />
        <ModalGateway>
          {viewerIsOpen ? (
            <Modal onClose={closeLightbox}>
              <Carousel
                currentIndex={currentImage}
                  views={photos.map(x =>
                    (
                      {
                        ...x,
                        srcset: x.srcSet,
                        caption: x.title
                      }
                    ))
                  }
              />
            </Modal>
          ) : null}
        </ModalGateway>
      </div>
    )
}
