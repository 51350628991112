import { types } from "../types/types";

export const activarGaleriaMultimedia = ( galeria ) => ({
    type: types.activaGaleriaMultimedia,
    payload: galeria
});

export const obtenerGaleriasMultimedia = ( galerias ) => ({
    type: types.obtenerGaleriasMultimedia,
    payload: galerias
});

export const guardarGaleriaMultimedia=(id)=>({
    type:types.guardarGaleriaMultimedia,
    payload:id
})