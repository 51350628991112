import React from 'react'
import { Row, Col } from 'react-bootstrap';
import { graphql, useStaticQuery } from 'gatsby';

export const Mision = () => {
    const { mision } = useStaticQuery(graphql`
        query {
            mision: file(relativePath: {eq: "colegio/mision.png"}){
                publicURL
            }
        }
    `)
    return (
        <Row>
            <Col xs={12} md={8}>
                <h2 className="has-text-white">Misión</h2>
                <p>
                    El colegio Cielos del Valle ha definido como misión el entregar a las familias de la Compañía Alta y a sus hijos en edad escolar, la oportunidad de participar en un proceso educativo formal de calidad, que les permita desarrollar una serie de competencias (conocimientos, habilidades y actitudes) que los lleven a formarse como seres humanos íntegros, capaces de vivir e incorporarse activamente a una sociedad local y regional diversa, y en constante cambio.
                </p>
            </Col>
            <Col xs={12} md={4}>
                <img 
                    src={mision.publicURL}
                    alt="" 
                /> 
            </Col>
        </Row>
    )
}
