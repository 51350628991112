import React, { useEffect } from 'react';

import { Row } from 'react-bootstrap'
import { useDispatch } from 'react-redux';
import { obtenerGaleriasMultimedia } from '../../action/galeria';
import useGaleriaImagenes from '../../hooks/useGaleriaImagenes';
import {MenuImg}  from './MenuImg';

export const GaleriaMultimedia = () => {
    const galeriaImagenes = useGaleriaImagenes();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(obtenerGaleriasMultimedia(galeriaImagenes))
		// eslint-disable-next-line react-hooks/exhaustive-deps
    },[])
    return (
		<Row>
			<Row>
				<h1>Galeria Multimedia</h1>
			</Row>
			<Row>
         	<div className="stretched">
				<section id="content">
					<div className="content-wrap">
						<div className="container clearfix">
							<div className="row grid-container"  style={{overflow: "visible"}}> 
								{
									galeriaImagenes && galeriaImagenes.map(galeria=>
										(
											<MenuImg 
											key={galeria.id}	
											galeria={galeria}/>
										))
								}
							</div>
						</div>
					</div>
			</section>
			</div>
			</Row>
        </Row>
    )
}


