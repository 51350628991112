import React from 'react'

export const MensajeSostenedora = () => {
    return (
        <>
            <h2 className="has-text-white">Mensaje de nuestra sostenedora</h2>
            <p className="has-text-justified">
                Hace algunos años comencé con este hermoso proyecto educativo.  En primera instancia me enfoqué a atender a niños pequeños en el Jardín Infantil, para luego continuar con la Escuela de Lenguaje. Al pasar los años, se ha conformado un equipo de trabajo con profesionales competentes y comprometidos con la tarea de educar  a las nuevas generaciones, lo que ha permitido establecer fuertes lazos de afectividad y compromiso con los estudiantes y sus padres y apoderados.
            </p>
            <p className="has-text-justified">
                Este nuevo proyecto tiene el propósito de dar respuesta a las necesidades educativas de la comunidad y apunta a cumplir con el anhelo de ofrecer a nuestros educandos la posibilidad de continuar sus estudios en una Escuela Básica regular,  que les ofrece un ambiente adecuado, exigente y a la vez acogedor, en que se les da la posibilidad de construir sus aprendizajes, desarrollar sus habilidades y actitudes y, sobre todo, el compromiso con  valores esenciales, de modo que puedan enfrentar la vida con éxito  e integrarse como personas a la sociedad, contribuyendo a ella desde sus propias  y particulares capacidades.
            </p>
            <p className="text-center">
                <em>Marjorie Vera Flores</em><br/>
                Sostenedora
            </p>

        </>
    )
}