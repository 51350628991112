import React from 'react'
import { Container, Jumbotron } from 'react-bootstrap';

export const Titulo = ({title, subtitle}) => {
    return (
        <Jumbotron className="bg-info d-flex align-items-center" style={{height:120}} fluid>
            <Container>
                <h1>{title}</h1>
                <p>{subtitle}</p>
            </Container>
        </Jumbotron>
    )
}
