import React from 'react'

export const Vision = () => {
    return (
        <>
            <h2 className="has-text-white">Visión</h2>
            <p className="has-text-justified">
                El Colegio Cielos del Valle anhela ofrecer a sus estudiantes y familias una educaciónpersonalizada y de calidad que les ayude a construir, a partir de sus propios aprendizajes, un proyecto de vida que los lleve a desarrollarse como personas integras, continuar con su proceso educativo permanente e integrarse a la sociedad, contribuyendo a ella desde sus propias y particulares capacidades. Para esta tarea contarán con la colaboración de un personal idóneo, conformado por docentes, profesionales y asistentes de la educación, que fortalecerán sus habilidades afectivas, sociales, artísticas y cognitivas.
            </p>
        </>
    )
}
                