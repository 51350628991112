import React from 'react'
import { useSelector } from 'react-redux';
import { Historia } from './Historia';
import { Mision } from './mision';
import { Vision } from './Vision';
import { MensajeSostenedora } from './MensajeSostenedora'
import { ProyectoEducativo } from './ProyectoEducativo'
import { ConvivenciaEscolar } from './ConvivenciaEscolar'
import { Reglamentos } from './Reglamentos'
import { PerfilEstudiante } from './PerfilEstudiante'
import { ModalidadEstudios } from './ModalidadEstudios';
import { GaleriaMultimedia } from './galeriaMultimedia';
import { CardGaleriaImagen } from './CardGaleriaImagen';

export const Contenido = () => {
    const {
        historia,
        mision,
        vision,
        mensajeSostenedora,
        proyectoEducativo,
        convivenciaEscolar,
        reglamentos,
        modalidadEstudios,
        perfilEstudiante,
        galeriaMultimedia,
        cardGaleriaImagen,
    } = useSelector(state => state.admin)
    return (
        <>
            {historia && <Historia />}
            {mision && <Mision />}
            {vision && <Vision />}
            {mensajeSostenedora && <MensajeSostenedora />}
            {proyectoEducativo && <ProyectoEducativo />}
            {convivenciaEscolar && <ConvivenciaEscolar />}
            {reglamentos && <Reglamentos />}
            {perfilEstudiante && <PerfilEstudiante />}
            {modalidadEstudios && <ModalidadEstudios />}
            {galeriaMultimedia && <GaleriaMultimedia />}
            {cardGaleriaImagen && <CardGaleriaImagen />}
        </>
    )
}