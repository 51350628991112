import * as React from 'react'
import Layout from '../components/layout'
import { Menu } from '../components/colegio/Menu'
import { Contenido } from '../components/colegio/Contenido'
import { Container, Row, Col } from 'react-bootstrap'
import { Titulo } from '../components/titulo'

export default function colegio () {
    return (
        <Layout>
            <Titulo title='Colegio Cielos del Valle' />
            <Menu />
            <Container>
                <Row>
                    <Col md={12}>
                        <Contenido />
                    </Col>
                </Row>
            </Container>
        </Layout>
    )
}